<template>
  <div>
        <div id="head">
        </div>        
        <div id="content" style="padding:6px;">          
          <div style="margin-top: 75px;">        
          </div>                               
        </div>
        <div id="footer">
        </div>
        <b-modal ref="my-lines" size="sm" centered @hide="onHide" id="modal-4" title="Line 登入" hide-header hide-footer>
                <Logins  /> 
                <div style="padding:0.4rem 0 0 0;">
                    <b-button type="button" pill variant="outline-primary" block @click="gohome">
                        <b-icon icon="house-fill" aria-label="Help"></b-icon>
                         回首頁
                    </b-button>                    
                </div>
             
        </b-modal>         
  </div>
</template>

<script>
// @ is an alias to /src
import Logins from "../auth/Login.vue";
import { mapState } from "vuex";
import router from '../router'

export default {
  name: 'Login',
  components: {
    Logins,
  },
  data() {
    return {
      iconLoading:false,     
    }
  },  
  computed: {
    ...mapState("account", ["status", "user"]),
  },  
  methods: {
    onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
      },  
    gohome(){
        router.replace({
            name: 'Home',    
        }).catch(err=>{err});        
    }  

  },
  mounted(){ 
    if(this.status.loggedIn){
        this.gohome()
    }else{
        this.$refs['my-lines'].show()
    }      

  }    
}
</script>
<style scoped>
.contantdiv{
  margin: 5px;
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow-x: hidden;
}

#head {
  height: 2rem;
  overflow-x: hidden;
}

#content {
  min-height: calc(75vh - 4rem);
  overflow-x: hidden;

}


#footer {
  height: 2rem;

}

</style>
